<template>
  <div class="view">
    <b-card v-if="item">
      <b-row>
        <b-col>
          <b-list-group flush class="list-group-dividered">
            <b-list-group-item><b>Имя: </b>{{ item.first_name }}</b-list-group-item>
            <b-list-group-item><b>Фамилия:</b> {{ item.surname }}</b-list-group-item>
            <b-list-group-item><b>Отчество:</b> {{ item.patronymic }}</b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col>
          <b-list-group flush class="list-group-dividered">
            <b-list-group-item><b>Email: </b> {{ item.email }}</b-list-group-item>
            <b-list-group-item><b>Телефон: </b> {{ item.phone }}</b-list-group-item>
            <b-list-group-item><b>Отдел: </b> {{ item.department }}</b-list-group-item>
            <b-list-group-item><b>Должность: </b> {{ item.position }}</b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>
      <b-tabs class="nav-tabs-line" content-class="pt-20" lazy>
        <b-tab title="Интервалы">
          <intervals entityName="employees" :id="$route.params.id"></intervals>
        </b-tab>
        <b-tab title="Сводка">
          <salary :user="(item.user && item.user.id) || null"></salary>
        </b-tab>
        <b-tab title="Мотивация"> </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
  import entityMixins from '../../mixins/entity'

  export default {
    name: 'SingleEmployee',
    components: {
      intervals: () => import('../../views/Reports/Intervals/Extension'),
      salary: () => import('../../views/Dashboard/Salary'),
    },
    mixins: [entityMixins],
    watch: {
      item(item) {
        this.$store.commit('currentView/setMeta', {
          title: item['surname'] + ' ' + item['first_name'],
        })
      },
    },
  }
</script>
